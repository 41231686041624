nav {
  height: 10vh;
  background-color: var(--Secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.3);
  z-index: 99;
}

.nav-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 100%;
}

.logo-text {
  color: var(--Primary);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
}

.logo-text > h1 {
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-text:hover {
  color: var(--SlightPrimary);
}

.logo-text > img {
  height: 3rem;
}

.nav-links {
  display: flex;
  align-items: center;
  list-style-type: none;
  gap: 2rem;
}

.nav-links > li > a,
.nav-links-mobile > li > a {
  color: inherit;
  text-decoration: none;
}

.nav-links > li,
.nav-links-mobile > li {
  color: var(--Primary);
  font-size: 1.1rem;
  font-weight: 600;
}

.nav-links > li:hover {
  color: var(--SlightPrimary);
}

.mobile-btn {
  display: none;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  cursor: pointer;
}

@media screen and (max-width: 950px) {
  .nav-links {
    display: none;
  }
  .mobile-btn {
    display: flex;
  }
  .nav-links-mobile {
    animation: slide-down 0.8s ease-in-out;
    background-color: var(--Secondary);
    position: absolute;
    list-style-type: none;
    right: 0;
    top: 10vh;
    padding: 1rem;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 2rem;
    width: 50%;
  }

  .nav-links-mobile > li {
    padding: 1rem;
    border-right: solid 2px var(--Primary);
    transition: all ease-in-out 0.3s;
  }
  .nav-links-mobile > li:hover {
    transform: translateX(-10px);
  }
}

@media screen and (max-width: 500px) {
  .nav-links-mobile {
    width: 100%;
    align-items: center;
  }
  .nav-links-mobile > li {
    border-right: none;
    width: 100%;
    text-align: center;
  }
  .logo-text > img {
    display: block;
  }
  .logo-text > h1 {
    font-size: 1.5rem;
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
