.explore {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem 0;
}

.explore-first-section {
  display: flex;
  align-items: flex-start;
  gap: 5rem;
  width: 80%;
  margin: 0 0 8rem 0;
}

.explore-first-section > img {
  width: 30rem;
  object-fit: cover;
  border-radius: 10px;
  flex: 6;
}

.explore-first-sec-text-content {
  justify-self: flex-start;
  flex: 6;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.explore-first-sec-text-content > h2 {
  color: var(--Primary);
}

.explore-first-sec-text-content > p {
  margin: 1rem 0;
  line-height: 2;
  font-size: 1.1rem;
  font-weight: 300;
  color: var(--LightPrimary);
}

.explore-carousel {
  width: 100%;
  margin: 3rem 0 1rem 0;
}

@media screen and (max-width: 940px) {
  .explore-first-section {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }

  .explore-first-section > img {
    height: 100%;
    width: 100%;
  }
}
