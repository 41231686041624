.footer {
  padding: 2rem;
  background-color: var(--Primary);
  color: white;
  display: flex;
  align-items: flex-start;
  padding-bottom: 5rem;
}

.footer > .footer-first-sec {
  flex: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
}
.footer > .footer-first-sec > h1 {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.footer-logo {
  height: 7vh;
}
.footer > .footer-first-sec > button > a {
  color: inherit;
}
.footer > .footer-first-sec > button {
  padding: 1rem 2rem;
  border: none;
  border: solid 1px var(--LightPrimary);
  background-color: transparent;
  color: white;
  font-size: 1rem;
  border-radius: 5px;
  font-weight: 600;
  transition: all ease-in-out 0.3s;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.footer > .footer-first-sec > button:hover {
  transform: scale(1.05);
  border: solid white 1px;
}

.footer-contact-us-icon {
  font-size: 1rem;
}
.footer > .footer-second-sec {
  flex: 2.5;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: flex-start;
  border-left: solid 1px white;
  font-size: 1rem;
  letter-spacing: 1px;
  color: rgb(185, 185, 185);
}

.footer > .footer-third-sec {
  flex: 2.5;
  padding: 2rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: flex-start;
  border-left: solid 1px white;
}
.footer > .footer-third-sec > .social-icons {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.footer > .footer-third-sec > .social-icons > button {
  border: none;
  background-color: transparent;
  color: white;
  font-size: 2rem;
  cursor: pointer;
}

@media screen and (max-width: 790px) {
  .footer {
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  }
  .footer > .footer-first-sec {
    align-items: center;
    margin-bottom: 1rem;
  }
  .footer > .footer-second-sec {
    align-items: center;
    border-left: none;
    border-bottom: solid 1px rgba(255, 255, 255, 0.5);
    border-top: solid 1px rgba(255, 255, 255, 0.5);
    width: 100%;
    padding: 4rem 0;
    margin: 1rem;
  }
  .footer > .footer-third-sec {
    border-left: none;
    align-items: center;
    gap: 2rem;
  }
}

@media screen and (max-width: 300px) {
  .footer > .footer-first-sec > h1 {
    font-size: 1.5rem;
  }
}
