.adbar {
  background-color: var(--Primary);
  color: white;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}

.adbar > p {
  font-weight: 400;
  font-size: 0.7rem;
  letter-spacing: 0.05rem;
  text-align: center;
}
