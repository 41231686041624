.our-science {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
}

.science-first-sec {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding: 1rem;
  margin: 3rem 0;
  width: 80%;
}

.science-first-sec > .science-first-sec-text-content {
  flex: 6;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}
.science-first-sec > .science-first-sec-text-content > h2 {
  font-size: 1.2rem;
  color: var(--Primary);
  letter-spacing: 1px;
}

.science-first-sec > .science-first-sec-text-content > p {
  color: var(--SlightPrimary);
  font-size: 1rem;
  letter-spacing: 1px;
}

.science-first-sec > .science-first-image-holder {
  flex: 6;
  height: 50vh;
}

.science-first-sec > .science-first-image-holder > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.science-second-sec {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 80%;
}
.science-second-sec > h1 {
  font-size: 2rem;
  color: var(--Primary);
}

.article-box {
  display: flex;
  margin: 5rem 0;
  gap: 5rem;
}
.reverse-row {
  flex-direction: row-reverse;
}
.article-text-content {
  flex: 6;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.article-text-content > h2 {
  color: var(--Chocolate);
}

.article-text-content > p {
  font-size: 1.2rem;
  font-weight: 300;
  letter-spacing: 1px;
  margin: 0.5rem 0 1rem 0;
}

.article-text-content > button > a {
  color: inherit;
}
.article-text-content > button {
  background-color: var(--Primary);
  border: none;
  font-size: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  transition: all ease-in-out 0.3s;
  color: var(--Pastle);
  font-weight: 600;
}
.article-text-content > button:hover {
  background-color: var(--Pastle);
  color: var(--Chocolate);
}

.article-image {
  flex: 6;
  height: 65vh;
  width: 30vw;
  object-fit: cover;
}

@media screen and (max-width: 1400px) {
  .science-first-sec {
    width: 100%;
    padding: 1rem 2rem;
  }
  .science-second-sec {
    width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .science-first-sec {
    flex-direction: column-reverse;
    margin-top: 0;
    padding: 1rem 1rem;
  }
  .article-box {
    gap: 1rem;
  }
}

@media screen and (max-width: 700px) {
  .science-second-sec > h1 {
    text-align: center;
  }
  .article-box {
    flex-direction: column;
    align-items: center;
  }
  .article-box > .article-image {
    width: 100%;
    height: 40vh;
  }
  .article-box > .article-text-content {
    align-items: center;
    text-align: center;
    gap: 1rem;
  }
}
